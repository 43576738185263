import React from "react";
import "./AppCard.css"; // Importing the CSS file for styling

function AppCard() {
  // Function to open the app on the Google Play Store
  const openPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.live.flighttracker"
     
    );
  };

  return (
    <div className="card" onClick={openPlayStore}>
      <img
       src="/assets/appImg.png" // Add the path to your Flights & Hotels image
        alt="Flights & Hotels"
        className="card-img"
      />
      <div className="card-text">
        <div className="card-heading">Download our App</div>
        <div className="card-paragraph">Real-time global flight tracking tool</div>
        <img
         src="/assets/playStore.png" // Add the path to the Google Play image
          alt="Google Play Store"
          className="playstore-img"
        />
      </div>
    </div>
  );
}

export default AppCard;