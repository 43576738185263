import React, { useEffect, useState } from "react";
import "./MapControls.css";

const MapControls = ({ map, isFlightsVisible, toggleFlights }) => {
  const [isFullScreen, setIsFullScreen] = useState(false); 

  const handleLocationPermission = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          map.flyTo({ center: [longitude, latitude], zoom: 6 });
        },
        (error) => {
          console.error("Error getting location:", error.message);
          if (error.code === error.PERMISSION_DENIED) {
            alert("Location access denied.");
          }
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const handleZoomIn = () => {
    if (map) {
      const zoom = map.getZoom();
      map.setZoom(Math.min(zoom + 1, 22));
    }
  };

  const handleZoomOut = () => {
    if (map) {
      const zoom = map.getZoom();
      map.setZoom(Math.max(zoom - 1, 0));
    }
  };

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <div className="map-controls">
      <button onClick={handleLocationPermission} className="control-btn" title="Current Location">
        <img src="/assets/currentLocation.png" alt="Current Location" />
      </button>

      <div className="zoom-control">
        <button onClick={handleZoomIn} className="zoom-btn" title="Zoom In">
          <img src="/assets/zoomIn.png" alt="Zoom In" />
        </button>
        <button onClick={handleZoomOut} className="zoom-btn" title="Zoom Out">
          <img src="/assets/zoomOut.png" alt="Zoom Out" />
        </button>
      </div>

      <button onClick={handleFullScreen} className="control-btn" title={isFullScreen ? "Exit Fullscreen" : "Go Fullscreen"}>
        <img 
          src={isFullScreen ? "/assets/full-screen.png" : "/assets/fullScreen.png"} 
          alt={isFullScreen ? "Exit Fullscreen" : "Go Fullscreen"} 
        />
      </button>

      {!isFlightsVisible && (
        <button onClick={toggleFlights} className="control-btn" title="Show Flights">
          <img src="/assets/flightAirplain.png" alt="Airport Image" />
        </button>
      )}
    </div>
  );
};

export default MapControls;