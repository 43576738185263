import React from 'react';
import './Terms.css';
import Header from './Header';

const Terms = () => {
  return (
    <>
      {/* You can uncomment the Header if needed */}
      <Header />
      <section className='terms-sections'>
      <h1 className='term-heading'>Terms of Use</h1>
      <div className='Terms-paragraph'>
        <h3>Last Updated: November 25, 2020</h3>
        <p>
          The Website and the Apps are provided by 12DTECHNOLOGY (hereinafter "12DTECHNOLOGY", "we", "us", or "our"). Your privacy is important to us. This document contains a policy statement regarding the collection, use, and processing of personal data, with whom we may share such data, and your rights in relation to personal data.
        </p>
        <h3>Personal Information (User-Controlled Option)</h3>
        <p>
          Our applications are non-obligatory log network. 12DTECHNOLOGY is not available for the information related to the personal information of users, including but not limited to names, addresses, and telephones, unless the data you provide depending on the context of your interactions with us.
        </p>

        <h3>Usage of Your Google Drive Account</h3>
        <p>
          We do not claim ownership in any of your content, including any text, data, information, and files that you upload, share, or store in your Drive account. You control who can access your files in Drive.
        </p>

        <h3>Service Information Related to Your App Usage</h3>
        <p>
          For the purpose to fulfill our contractual obligations in good faith and to make you enjoy excellent app services, we collect the following information related to your app usage: Current Location, Text Message, Camera, Contacts, Storage, Calendar Events, etc. We do not save or share this information without your permission.
        </p>

        <h3>Conclusion</h3>
        <p>
          We collect minimal usage statistics to maintain the quality of our services. We stand by our firm commitment to our customers' privacy by not possessing any data related to a user's online or offline activities.
        </p>

        <h3>The Use and Sharing of Information</h3>
        <p>
          We use the information we collect in various ways, including to: 
          <ul>
            <li>Share, save, and delete data or files within Apps;</li>
            <li>Improve, enhance, and modify data using Apps;</li>
            <li>Understand and analyze how you want to use the app;</li>
            <li>Develop new products, services, features, and functionality;</li>
            <li>Communicate with you for marketing and promotional purposes;</li>
            <li>Find and prevent fraud;</li>
          </ul>
        </p>

        <h3>Advertising</h3>
        <p>
          We do not serve any ads for the VIP subscription of our services. However, we offer free, advertising-supported App services. We do not share your personal information with advertisers without your prior consent.
        </p>

        <h3>Jurisdiction and Applicable Law</h3>
        <p>
          Keeping your information private is our core mission. The registered place of business is in Pakistan. There are no special data retention laws and no guidance regarding e-discovery or foreign law enforcement agencies.
        </p>

        <h3>Data Retention</h3>
        <p>
          We retain personal information where we have an ongoing legitimate business need to do so. When we have no ongoing legitimate business need, we will delete or anonymize your data.
        </p>

        <h3>Security Measures</h3>
        <p>
          12DTECHNOLOGY is committed to protecting your information. We use best-in-class physical, procedural, and technical security measures to prevent any loss, misuse, or unauthorized access.
        </p>

        <h3>Third-Party Services</h3>
        <p>
          You may access other third-party services while using our App services. We are not responsible for the privacy policies of these third-party services, and we encourage you to review their privacy policies carefully.
        </p>

        <h3>Children's Privacy</h3>
        <p>
          We do not knowingly collect information from children under the age of 16. If you learn that a child has provided us with personal information, please contact us.
        </p>

        <h3>Access</h3>
        <p>
          When you become one of our users for the app services, you may access certain information associated with you by contacting us.
        </p>

        <h3>GDPR</h3>
        <p>
          12DTECHNOLOGY is committed to user privacy globally. We will process your personal information according to the requirements of the General Data Protection Regulation (GDPR).
        </p>

        <h3>International Data Transfers</h3>
        <p>
          12DTECHNOLOGY may transfer personal information to countries other than the country in which the data was originally collected. When we do, we will protect that information as described in this Privacy Policy.
        </p>

        <h3>Changes to This Privacy Policy</h3>
        <p>
          This Privacy Policy may be modified from time to time without prior notice. Your continued use of our app services constitutes your acceptance of our Privacy Policy.
        </p>

        <h3>Contacts</h3>
        <p>
  If you have any questions or concerns about this Privacy Policy, please feel free to contact us at 

  <a href="mailto:feedback@airportflightsstatus.com"> feedback@airportflightsstatus.com</a>.
</p>

        <h3>Thank You!</h3>
      </div>
      </section>
    </>
  );
};

export default Terms;