import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import AppCard from '../AppCard'; // Import AppCard component

const Header = () => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('/'); // Set Home as active by default
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState('ENG');
  const [currentTime, setCurrentTime] = useState('');

  const handleButtonClick = (path) => {
    if (path === '/') {
      setActiveButton('/'); // Set Home as active
    } else {
      setActiveButton(path); // Set active button based on clicked button
    }
  };

  const toggleMobileMenu = () => {
    setShowMobileMenu((prev) => !prev);
  };

  useEffect(() => {
    const updateTime = () => {
      const date = new Date();
      const gmtTime = date.toLocaleTimeString('en-GB', {
        timeZone: 'Etc/GMT-5',
        hour: '2-digit',
        minute: '2-digit',
      });
      setCurrentTime(`GMT +5 ${gmtTime}`);
    };
    updateTime();
    const intervalId = setInterval(updateTime, 60000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const path = location.pathname === '/' ? '/' : `/${location.pathname.split('/')[1]}`; // Ensure we get the correct path
    setActiveButton(path); // Set active button based on current path
  }, [location.pathname]);

  return (
    <header className="header">
      <div className="logo">
        <Link to="/" onClick={() => handleButtonClick('/')}>
          <div className="logo1">
            <img className="mobile-logo" src="./assets/logo-text.png" alt="Logo" />
          </div>
        </Link>
      </div>

      <div className="clock-section-mobile">
        <img src="./assets/clock.png" className="clock-icon" alt="Clock" />
        <span className="current-time-mobile">{currentTime}</span>

        <div
          className={`menu-icon ${showMobileMenu ? 'active' : ''}`} // Add conditional class for active state
          onClick={toggleMobileMenu}
        >
          {showMobileMenu ? (
            <img className="menu-cros" src="./assets/menu-cross.png" alt="Close menu" />
          ) : (
            <img src="./assets/menu-bar.png" alt="Open menu" />
          )}
        </div>
      </div>

      <nav className="navigation">
        {/* Change the button click handler for "Terms" to allow navigation */}
        {['Home', 'About', 'Terms', 'ContactUs'].map((btn, index) => {
          const path = `/${btn.replace(/\s+/g, '-').toLowerCase()}`;
          const isActive = activeButton === (btn === 'Home' ? '/' : path);
          return (
            <Link
              key={index}
              to={btn === 'Home' ? '/' : path}
              className={`nav-button ${isActive ? 'active' : ''}`}
              onClick={(e) => {
                handleButtonClick(btn === 'Home' ? '/' : path); // Update active button
                // Allow navigation for the "Terms" button 
                if (btn !== 'Home' && btn !== 'Terms') {
                  e.preventDefault(); // Prevent navigation for non-home and non-terms buttons
                }
              }}
            >
              {btn}
            </Link>
          );
        })}
      </nav>

      <div className={`mobile-menu ${showMobileMenu ? 'active' : ''}`}>
        {['Home', 'About', 'Terms', 'ContactUs'].map((btn, index) => {
          const path = `/${btn.replace(/\s+/g, '-').toLowerCase()}`;
          return (
            <Link
              key={index}
              to={btn === 'Home' ? '/' : path}
              className={`nav-button ${activeButton === (btn === 'Home' ? '/' : path) ? 'active' : ''}`}
              onClick={(e) => {
                handleButtonClick(btn === 'Home' ? '/' : path); // Update active button
                // Allow navigation for the "Terms" button 
                if (btn !== 'Home' && btn !== 'Terms') {
                  e.preventDefault(); // Prevent navigation for non-home and non-terms buttons
                }
              }}
            >
              {btn}
            </Link>
          );
        })}

        {/* Add AppCard component at the bottom of the mobile menu */}
        {/* <div className='menu-app-card'> */}
          {/* <AppCard /> */}
        {/* </div> */}
      </div>

      <div className="right-section">
        <div className="language-selector">
          <span className="current-language">{currentLanguage}</span>
        </div>
        <div className="clock-section">
          <img src="./assets/clock.png" className="clock-icon" alt="Clock" />
          <span className="current-time">{currentTime}</span>
        </div>
      </div>
    </header>
    
  );
};

export default Header;