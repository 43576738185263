import React, { useState, useEffect, useRef } from "react";
import "./FlightSearch.css"; // Updated styles here
import Header from "./Header/Header";
import AirportCard from "./HomePage/Flight Info Card/AirportCard";
import AirlineCard from './HomePage/Flight Info Card/AirlineCard';
import TrendingFlightCard from "./TrendingFlightCard";

const FlightSearch = ({
  flights,
  onFlightSelect,
  onSearchFocus,
  onSearchBlur,
  selectedFlight,
  showAirportCard,
  setShowAirportCard,
  showAirlineCard,
  setShowAirlineCard
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchCategory, setSearchCategory] = useState("Flight No");
  const [isActive, setIsActive] = useState(false);
  const [visibleItems, setVisibleItems] = useState(6);
  const [airports, setAirports] = useState([]);
  const flightListRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFlightNo, setSelectedFlightNo] = useState("");
  const [shouldFetchAirports, setShouldFetchAirports] = useState(true);
  const [airlines, setAirlines] = useState([]);
  const [shouldFetchAirlines, setShouldFetchAirlines] = useState(true);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [selectedAirline, setSelectedAirline] = useState(null);
  // const [showAirlineCard, setShowAirlineCard] = useState(false);

  // Fetch airport data
  const fetchAirports = async () => {
    try {
      const response = await fetch("https://flight.matcheclub.com/api/airports/");
      if (!response.ok) throw new Error("Failed to fetch airport data");
      const data = await response.json();
      setAirports(data);
      setShouldFetchAirports(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  // Fetch airline data
  const fetchAirlines = async () => {
    try {
      const response = await fetch("https://flight.matcheclub.com/api/airlines/");
      if (!response.ok) throw new Error("Failed to fetch airline data");
      const data = await response.json();
      setAirlines(data);
      setShouldFetchAirlines(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (shouldFetchAirports) {
      fetchAirports();
    }
  }, []);

  useEffect(() => {
    if (searchCategory === "Airline" && shouldFetchAirlines) {
      fetchAirlines();
    }
  }, [searchCategory]);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (searchCategory === "Flight No") {
      setFilteredData(
        flights.filter((flight) =>
          flight.flight.iataNumber?.toLowerCase().includes(term.toLowerCase())
        )
      );
    } else if (searchCategory === "Airport") {
      setFilteredData(
        airports.filter(
          (airport) =>
            (airport.name && airport.name.toLowerCase().includes(term.toLowerCase())) ||
            (airport.id && airport.id.toLowerCase().includes(term.toLowerCase()))
        )
      );
    } else if (searchCategory === "Airline") {
      setFilteredData(
        airlines.filter(
          (airline) =>
            airline.Name?.toLowerCase().includes(term.toLowerCase()) ||
            (airline.ICAO && airline.ICAO.toLowerCase().includes(term.toLowerCase()))
        )
      );
    }

    setVisibleItems(6); // Reset visible items count when filtering
  };

  const clearSearch = () => {
    setSearchTerm("");
    setFilteredData([]);
    setIsActive(false);
    setVisibleItems(6);
    setSelectedFlightNo("");
    setSelectedAirport(null);
    setSelectedAirline(null);
    setShowAirportCard(false); // Ensure the airport card is closed
    setShowAirlineCard(false);
    onSearchBlur();
  };

  const handleFocus = () => {
    setIsActive(true);
    onSearchFocus();

    if (searchCategory === "Flight No") {
      setFilteredData(flights);
    } else if (searchCategory === "Airport") {
      setFilteredData(airports);
    } else if (searchCategory === "Airline") {
      setFilteredData(airlines);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setShowDropdown(!showDropdown);
  };

  const selectCategory = (category) => {
    setSearchCategory(category);
    setShowDropdown(false);
    if (category === "Airline" && shouldFetchAirlines) {
      fetchAirlines();
    }
    setFilteredData(
      category === "Flight No"
        ? flights
        : category === "Airport"
        ? airports
        : category === "Airline"
        ? airlines
        : flights
    );
  };

  // Handle selecting an item by pressing Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission

      const term = searchTerm.toLowerCase();
      let selectedItem = null;

      if (searchCategory === "Flight No") {
        selectedItem = flights.find(flight => flight.flight.iataNumber?.toLowerCase() === term);
        if (selectedItem) {
          handleFlightSelect(selectedItem);
        }
      } else if (searchCategory === "Airport") {
        selectedItem = airports.find(airport => airport.name?.toLowerCase() === term || airport.id?.toLowerCase() === term);
        if (selectedItem) {
          handleAirportSelect(selectedItem);
        }
      } else if (searchCategory === "Airline") {
        selectedItem = airlines.find(airline => airline.Name?.toLowerCase() === term || airline.ICAO?.toLowerCase() === term);
        if (selectedItem) {
          handleAirlineSelect(selectedItem);
        }
      }
    }
  };

  const handleFlightSelect = (flight) => {
    if (onFlightSelect && typeof onFlightSelect === "function") {
      onFlightSelect(flight);
    }
    setSelectedFlightNo(flight.flight.iataNumber);
    setSearchTerm(flight.flight.iataNumber);
    setIsActive(false);

    // Hide the airport card when a flight is selected
    setShowAirportCard(false);
    setShowAirlineCard(false); 
  };

  const handleAirportSelect = (airport) => {
    setSelectedAirport(airport.iata);
    setShowAirportCard(true);
    setSearchTerm(`${airport.name} (${airport.iata})`);
    setIsActive(false);
    setShowAirlineCard(false)
    
    // Reset the flight selection when an airport is selected
    if (onFlightSelect) {
        onFlightSelect(null);
    }
  };

  const handleAirlineSelect = (airline) => {
    setSelectedAirline(airline.ICAO);
    setShowAirlineCard(true);
    setSearchTerm(`${airline.Name} (${airline.ICAO})`);
    setIsActive(false);
    setShowAirportCard(false);

    if (onFlightSelect) {
      onFlightSelect(null);
  }
  };

  const handleScroll = () => {
    const list = flightListRef.current;
    if (list.scrollTop + list.clientHeight >= list.scrollHeight) {
      setVisibleItems((prev) => prev + 6); // Load 6 more flights
    }
  };

  const handleCloseAirportCard = () => {
    setShowAirportCard(false); // Close the AirportCard
  };

  const handleCloseAirlineCard = () => {
    setShowAirlineCard(false); // Close the AirlineCard
  };

  return (
    <>
      <div className="top-search">
        <div className="flight-searchbar-container">
          <div className="search-bar">
            <div className="searchIcon-heading">
              <button onClick={clearSearch} className="icon-btn">
                {isActive || selectedFlightNo || selectedAirport || selectedAirline ? (
                  <img className="icon" src="/assets/cross.png" alt="cancel" />
                ) : (
                  <img className="icon" src="/assets/search.png" alt="Search" />
                )}
              </button>

              <input
                type="text"
                placeholder={`Search by ${searchCategory}`}
                value={searchTerm}
                onChange={handleSearch}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown} // Add keydown handler
              />
            </div>

            <div className="dropdown">
              <span className="vr1"></span>
              <button className="dropdown-btn" onClick={toggleDropdown}>
                <span className="search-heading">{searchCategory}</span>
                <span>
                  {isOpen ? <img src="/assets/uplist-black.png" /> : <img src="/assets/downlist-black.png" />}
                </span>
              </button>

              {showDropdown && (
                <div className="dropdown-content">
                  <div onClick={() => selectCategory("Flight No")}>
                    <img src="/assets/flightNoIcon.png" />
                    <span className="select-flight-no">Flight No</span>
                  </div>
                  <div onClick={() => selectCategory("Airport")}>
                    <img src="/assets/airportIcon.png" />
                    <span className="nonselect-flight-no">Airport</span>
                  </div>
                  <div onClick={() => selectCategory("Airline")}>
                    <img src="/assets/airlineIcon.png" />
                    <span className="nonselect-flight-no">Airline</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isActive && (
            <div
              className="flight-list"
              style={{ overflowY: "auto", scrollBehavior: "smooth" }}
              onScroll={handleScroll}
              ref={flightListRef}
            >
              <div className="list-background">
                {filteredData.length > 0 ? (
                  filteredData.slice(0, visibleItems).map((item, index) => (
                    searchCategory === "Airport" ? (
                      <div
                        className="flight-item"
                        key={index}
                        onClick={() => handleAirportSelect(item)}
                      >
                        <div className="listfirst-icon">
                          <img src="/assets/airport.png" />
                          <div className="flight-search-details">
                            <div className="flight-no">
                              {item.name} ({item.iata})
                            </div>
                          </div>
                        </div>
                        <div className="flight-search-right">
                          <img className="icon-next" src="/assets/next.png" />
                        </div>
                      </div>
                    ) : searchCategory === "Airline" ? (
                      <div
                        className="flight-item"
                        key={index}
                        onClick={() => handleAirlineSelect(item)}
                      >
                        <div className="listfirst-icon">
                          <img src="/assets/hello.png" />
                          <div className="flight-search-details">
                            <div className="flight-no">
                              {item.Name} ({item.ICAO})
                            </div>
                          </div>
                        </div>
                        <div className="flight-search-right">
                          <img className="icon-next" src="/assets/next.png" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="flight-item"
                        key={item.flight.icaoNumber}
                        onClick={() => handleFlightSelect(item)}
                      >
                        <div className="listfirst-icon">
                          <img src="/assets/listfirst.png" />
                          <div className="flight-search-details">
                            <div className="flight-no">
                              {item.flight.iataNumber}
                            </div>
                            <div className="flight-route">
                              {item.departureCountry} - {item.arrivalCountry}
                            </div>
                          </div>
                        </div>
                        <div className="flight-search-right">
                          <div className="flight-search-status">
                            <div className="flight-iata">
                              {item.departure.iataCode} - {item.arrival.iataCode}
                            </div>
                            <div className="flight-status-text">
                              {item.status ? item.status : "N/A"}
                            </div>
                          </div>
                          <div className="flight-next-icon">
                            <img className="icon-next" src="/assets/next.png" />
                          </div>
                        </div>
                      </div>
                    )
                  ))
                ) : (
                  <div className="no-flights">No results available.</div>
                )}
              </div>
            </div>
          )}
        </div>

        {showAirportCard && (
          <AirportCard
            airportCode={selectedAirport}
            onClose={handleCloseAirportCard}
          />
        )}

        {showAirlineCard && (
          <AirlineCard
            airlineCode={selectedAirline}
            onClose={handleCloseAirlineCard}
          />
        )}

        {!showAirportCard && !showAirlineCard && !selectedFlight && (
          <TrendingFlightCard flights={flights} />
        )}
      </div>
      <Header />
    </>
  );
};

export default FlightSearch;