import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { FlightProvider } from './Pages/Api File/FlightApi'; // Adjust the path as necessary
import Map from './Pages/HomePage/ScreenMap/Map';
import TopAirports from './Pages/TrendingFlight/TopAirports';
import SearchAirports from './Pages/SearchAirports';
import AirportCard from './Pages/HomePage/Flight Info Card/AirportCard';
import Terms from './Pages/Header/Terms';
import Header from './Pages/Header/Header';

const App = () => {
  return (
    <FlightProvider>
      <Router>
        {/* You can uncomment Header if needed */}
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<Map />} />
          <Route path="/Terms" element={<Terms />} />
          {/* Add any additional routes here as needed */}
        </Routes>
      </Router>
    </FlightProvider>
  );
};

export default App;