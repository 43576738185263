import React, { useState, useEffect, useRef } from "react";
import "./TrendingFlightCard.css";
import TopAirports from './TrendingFlight/TopAirports';
import loadingGif from './TrendingFlight/loading1.gif'; // Your GIF path

const TrendingFlightCard = ({ flights }) => {
  const [open, setOpen] = useState(true);
  const [visibleFlights, setVisibleFlights] = useState(15);
  const [isLoading, setIsLoading] = useState(true);
  const observer = useRef();

  // Define country abbreviations
  const abbreviations = {
    "United Arab Emirates": "UAE",
    "Saudi Arabia": "KSA",
    "United Kingdom": "UK",
    "United States": "USA",
    "South Korea": "Korea",
    "North Korea": "DPRK",
    "New Zealand": "NZ",
    "Papua New Guinea": "PNG",
    "Central African Republic": "CAR",
    "Bosnia and Herzegovina": "BiH",
    "Antigua and Barbuda": "A&B",
    "Dominican Republic": "DR",
    "Equatorial Guinea": "Eq. Guinea",
    "Trinidad and Tobago": "T&T",
    "Sao Tome and Principe": "Sao Tome",
    "Netherlands Antilles":"Neth Antilles",
    "Netherlands": "NLD",
  };

  // Function to abbreviate long country names
  const abbreviateCountry = (country) => 
    country.length > 8 ? abbreviations[country] || country : country;

  useEffect(() => {
    const loadMoreFlights = (entries) => {
      if (entries[0].isIntersecting) {
        setVisibleFlights((prev) => Math.min(prev + 3, flights.length));
      }
    };

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observerInstance = new IntersectionObserver(loadMoreFlights, options);

    if (observer.current) {
      observerInstance.observe(observer.current);
    }

    return () => {
      if (observer.current) {
        observerInstance.unobserve(observer.current);
      }
    };
  }, [flights]);

  // Simulate loading delay for demonstration
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 7000); // Adjust the timeout as needed
    return () => clearTimeout(timer);
  }, []);

  // Toggle the open state when clicking the header
  const toggleCard = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <div className={`trending-flights ${open ? "" : "closed"}`}>
      <div>
        <div className="card-header" onClick={toggleCard}>
          <span className={`trending-heading ${open ? "open" : "closed"}`}>
            Trending Flights
          </span>
          <span>
            {open ? (
              <img className="trending-icon" src="/assets/uplist-black.png" alt="Collapse" />
            ) : (
              <img className="trending-icon" src="/assets/downlist-black.png" alt="Expand" />
            )}
          </span>
        </div>

        {open && (
          <div
            className="Trendingflights-list"
            style={{ overflowY: "auto", scrollBehavior: "smooth", scrollbarWidth: "none" }}
          >
            {isLoading ? (
              <div className="loading">
              <div className="loading-animation" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '120px' }}>
                <img src={loadingGif} alt="Loading..."/>
              </div>
              </div>
            ) : (
              <ul className="ul">
                {flights.slice(0, visibleFlights).map((flight, index) => (
                  <li key={index} className="Trendingflight-item">
                    <div className="Trendingflight-info">
                      <div className="Trendingflight-left">
                        <div>
                          <span className="Trendingflight-details">Flight no: </span>
                          <span className="Trendingflight-number">
                            {flight.flight?.iataNumber || "N/A"}
                          </span>
                        </div>
                        <div className="Trendingflight-details">
                          <span>{abbreviateCountry(flight.departureCountry) || "N/A"}</span> - <span>{abbreviateCountry(flight.arrivalCountry) || "N/A"}</span>
                        </div>
                      </div>

                      <div className="Trendingflight-right">
                        <div className="Trendingflight-route">
                          <span className="departure-code">
                            {flight.departure?.iataCode || "N/A"} - {flight.arrival?.iataCode || "N/A"}
                          </span>
                        </div>
                        <div
                          className={`Trendingflight-status ${flight.status?.toLowerCase() || ""}`}
                        >
                          {flight.status || "N/A"}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
  <div>
  <TopAirports />
  </div>
    </div>
  );
};

export default TrendingFlightCard;