import React, { useState, useEffect } from "react";
import "./AirlineCard.css"; // Ensure this contains your styles
import AppCard from "../../AppCard";
import loadingGif from '../../TrendingFlight/loading1.gif'; // Adjust path as necessary

const AirlineCard = ({ airlineCode, onClose }) => {
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [airlineLogo, setAirlineLogo] = useState("");
  // const [showAppCard, setShowAppCard] = useState(false);

  // useEffect(() => {
  //   const checkScreenHeight = () => {
  //     if (window.innerHeight < 1000) { // If screen height is less than 100vh, hide AppCard
  //       setShowAppCard(false);
  //     } else {
  //       setShowAppCard(true);
  //     }
  //   };

  //   checkScreenHeight();
  //   window.addEventListener('resize', checkScreenHeight);

  //   return () => {
  //     window.removeEventListener('resize', checkScreenHeight);
  //   };
  // }, []);

  useEffect(() => {
    const fetchFlightData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `https://flight.matcheclub.com/api/flight-by-airline/?airline=${airlineCode}`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              "X-CSRFTOKEN": "sQTG09i2ATNY7uJp07j5j5lVIzZhlbDE",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch flight data");
        }

        const data = await response.json();
        const flightData = data.results
          .filter((item) => item.type === "live" || item.type === "schedule")
          .map((item) => ({
            flightNumber: item.detail.flight || "N/A",
            route: item.detail.route || "",
            departure: item.detail.route
              ? item.detail.route.split(" ⟶ ")[0]
              : "",
            arrival: item.detail.route
              ? item.detail.route.split(" ⟶ ")[1]
              : "",
            logo: item.detail.logo || "",
          }))
          .filter((flight) => flight.departure && flight.arrival);

        if (flightData.length > 0) {
          setAirlineLogo(flightData[0].logo);
        }
        setFlights(flightData);
      } catch (err) {
        setError(err.message || "Unknown error");
      } finally {
        setLoading(false);
      }
    };

    fetchFlightData();
  }, [airlineCode]);

  if (!isVisible) return null;

  return (
    <div className="flight-card-container-airline">
      <div className="flight-card-header-airline">
        <div className="airline-heading-detais">
          <div className="airline-heading-plus-logo">
            {airlineLogo && (
              <img
                className="airline-logo"
                src={airlineLogo}
                alt={`${airlineCode} Logo`}
              />
            )}
            <h2 className="airline-heading">{airlineCode} Air Lines</h2>
          </div>
          <button className="close-btn" onClick={onClose}>
            <img src="/assets/close.png" alt="Close" />
          </button>
        </div>
      </div>

      <div className="flight-list-airline">
        {loading ? (
          <div className="loading-animation">
            {/* Replace Lottie with Loading GIF */}
            <img src={loadingGif} alt="Loading..." height={150} width={150} />
          </div>
        ) : error ? (
          <p>{error}</p>
        ) : flights.length > 0 ? (
          <ul className="airport-ul"> {/* Added ul to wrap the flight items */}
            {flights.map((flight, index) => (
              <li key={index} className="flight-item-airline">
                <div className="flight-item-airline-info">
                  <div className="flight-number-airline">
                    <div>
                      <span className="airine-flight-no">Flight no: </span>
                      <span className="airine-flight-no-value">{flight.flightNumber}</span>
                    </div>
                    <div>
                      <img className="airline-card-next" src="/assets/airline-card-next.png" alt="Next" />
                    </div>
                  </div>
                  <div className="flight-route-airline">
                    <span className="airline-dep-arri">{flight.departure}</span>{" "}
                    <span className="airline-dep-arri">{flight.arrival}</span>
                  </div>
                </div>
              </li> // Each flight item is now wrapped in an li element
            ))}
          </ul>
        ) : (
          <p>This Time No flights Available on this Airport</p>
        )}
      </div>

      <div className="airport-card-bottom">
       <AppCard />
      </div>
    </div>
  );
};

export default AirlineCard;