import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import axios from "axios";
import Papa from "papaparse";

const FlightContext = createContext();

const AIRPORT_CSV_PATH = "/airports.csv"; // Path to the CSV file

export const FlightProvider = ({ children }) => {
  const [flights, setFlights] = useState([]);
  const [airportData, setAirportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flightDetails, setFlightDetails] = useState([]);
  const [selectedFlightPath, setSelectedFlightPath] = useState([]);
  const [airportDataLoaded, setAirportDataLoaded] = useState(false);

  // Map specific states
  const [selectedFlight, setSelectedFlight] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [coveredDistance, setCoveredDistance] = useState(0);
  const [mapCenter, setMapCenter] = useState([37.0902, -95.7129]); // Default to USA center

  const apiKey = process.env.REACT_APP_AVIATION_API_KEY || "8565bc-a3e048";

  const fetchFlights = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://aviation-edge.com/v2/public/flights?key=${apiKey}&limit=8000`
      );
      const allFlights = response.data;
      if (Array.isArray(allFlights) && airportDataLoaded) {
        const validFlights = allFlights.filter(
          (flight) =>
            flight.status === "en-route" &&
            flight.flight?.iataNumber &&
            flight.flight.iataNumber !== "XXD" &&
            getAirportInfo(flight.departure?.iataCode) &&
            getAirportInfo(flight.arrival?.iataCode)
        );
        setFlights(validFlights);
      }
    } catch (error) {
      console.error("Error fetching flight data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFlightDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://aviation-edge.com/v2/public/timetable?key=${apiKey}&limit=15000`
      );
      setFlightDetails(response.data);
    } catch (error) {
      console.error("Error fetching flight details:", error);
    }
  }, [apiKey]);

  const loadCSVData = async () => {
    Papa.parse(AIRPORT_CSV_PATH, {
      download: true,
      header: false,
      complete: (result) => {
        const structuredData = result.data.map((row) => ({
          city: row[0],
          country: row[1],
          depCode: row[2],
          arrCode: row[3],
          airportName: row[4],
          latitude: parseFloat(row[5]),
          longitude: parseFloat(row[6]),
        }));
        setAirportData(structuredData);
        setAirportDataLoaded(true);
      },
      error: (error) => {
        console.error("Error loading CSV:", error);
      },
    });
  };

  const getAirportInfo = (iataCode) => {
    return airportData.find(
      (airport) => airport.depCode === iataCode || airport.arrCode === iataCode
    );
  };

  const showFlightPath = (flight) => {
    if (!flight || !flight.geography) return;

    const { latitude, longitude } = flight.geography;
    const departureAirport = getAirportInfo(flight.departure.iataCode);
    const arrivalAirport = getAirportInfo(flight.arrival.iataCode);

    if (departureAirport && arrivalAirport) {
      const flightPath = [
        [departureAirport.latitude, departureAirport.longitude],
        [latitude, longitude],
        [arrivalAirport.latitude, arrivalAirport.longitude],
      ];
      setSelectedFlightPath(flightPath);
    }
    setSelectedFlight(flight);

    const totalDist = haversineDistance(
      departureAirport?.latitude || 0,
      departureAirport?.longitude || 0,
      arrivalAirport?.latitude || 0,
      arrivalAirport?.longitude || 0
    );

    const coveredDist = haversineDistance(
      departureAirport?.latitude || 0,
      departureAirport?.longitude || 0,
      latitude,
      longitude
    );

    setTotalDistance(totalDist);
    setCoveredDistance(coveredDist);

    setMapCenter([latitude, longitude]); // Update map center to selected flight
  };

  const removeFlightPath = () => {
    setSelectedFlightPath([]);
    setSelectedFlight(null);
  };

  const haversineDistance = (lat1, lon1, lat2, lon2) => {
    const toRad = (value) => (value * Math.PI) / 180;
    const R = 6371; // Earth's radius in kilometers

    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return parseFloat((R * c).toFixed(0)); // Distance in kilometers
  };

  useEffect(() => {
    fetchFlights();
    fetchFlightDetails();
    loadCSVData();
  }, [apiKey, airportDataLoaded]);

  return (
    <FlightContext.Provider
      value={{
        flights,
        flightDetails,
        loading,
        getAirportInfo,
        showFlightPath,
        removeFlightPath,
        selectedFlight,
        totalDistance,
        coveredDistance,
        mapCenter,
        setSelectedFlight,
      }}
    >
      {children}
    </FlightContext.Provider>
  );
};

export const useFlightAPI = () => {
  return useContext(FlightContext);
};