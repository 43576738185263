import React, { useState,useMemo,useEffect,useRef} from "react";
import './FlightCard.css';
import { FaCaretRight } from 'react-icons/fa';
import AppCard from "../../AppCard";
import { isEqual } from 'lodash';

const FlightCard =  React.memo( ({ flightInfo, departureInfo, arrivalInfo, onClose, flightDetails, coveredDistance, totalDistance }) => {
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const [isLiveInfoOpen, setLiveInfoOpen] = useState(false);
  const [isAircraftInfoOpen, setAircraftInfoOpen] = useState(false);
  const [isFlightInfoOpen, setFlightInfoOpen] = useState(false);
  // const [showAppCard, setShowAppCard] = useState(false);
  const mapRef = useRef(null); // Ref for the map
  
  // useEffect(() => {
  //   const checkScreenHeight = () => {
  //     if (window.innerHeight < 900) { // If screen height is less than 100vh, hide AppCard
  //       setShowAppCard(false);
  //     } else {
  //       setShowAppCard(true);
  //     }
  //   };

  //   checkScreenHeight();
  //   window.addEventListener('resize', checkScreenHeight);

  //   return () => {
  //     window.removeEventListener('resize', checkScreenHeight);
  //   };
  // }, []);


  // Helper function to format time to HH:MM
  const formatTime = (dateTime) => {
    if (!dateTime) return "N/A";
    const date = new Date(dateTime);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  if (!flightInfo) return null;

  const departureIataCode = flightInfo.departure?.iataCode || "N/A";
  const arrivalIataCode = flightInfo.arrival?.iataCode || "N/A";
  const flightNumber = flightInfo.flight?.iataNumber || "N/A";
  const airlineStatus = flightInfo.status || "N/A";

  // Format times using the helper function
  const actualArrival = formatTime(flightDetails.arrival?.actualTime);
  const estimatedArrival = formatTime(flightDetails.arrival?.estimatedTime);
  const actualScheduled = formatTime(flightDetails.departure?.scheduledTime);
  const estimatedScheduled = formatTime(flightDetails.arrival?.scheduledTime);

  const airlineName = flightDetails?.airline?.name || "N/A";
  const airlineReg = flightInfo?.aircraft?.regNumber || "N/A";

  const depCity = departureInfo?.city || "N/A";
  const depCountry = departureInfo?.country || "N/A";
  const depAirport = departureInfo?.airportName || "N/A";
  const depTerminal = flightDetails?.departure?.terminal || "N/A";
  const depGate = flightDetails?.departure?.gate || "N/A";

  const arrivalCity = arrivalInfo?.city || "N/A";
  const arrivalCountry = arrivalInfo?.country || "N/A";
  const arrivalAirport = arrivalInfo?.airportName || "N/A";
  const arrivalTerminal = flightDetails?.arrival?.terminal || "N/A";
  const arrivalGate = flightDetails?.arrival?.gate || "N/A";
  const baggageInfo = flightDetails?.baggage || "N/A";

  // Live Flight Info
  const liveAltitude = flightInfo.geography?.altitude || "N/A";
  const liveSpeed = flightInfo.speed?.horizontal || "N/A";
  const liveLatitude = flightInfo.geography?.latitude || "N/A";
  const liveLongitude = flightInfo.geography?.longitude || "N/A";
  const liveHeading = flightInfo.geography?.direction || "N/A";

  const handleToggle = (section) => {
    switch (section) {
      case "details":
        setDetailsOpen(!isDetailsOpen);
        break;
      case "live":
        setLiveInfoOpen(!isLiveInfoOpen);
        break;
      case "aircraft":
        setAircraftInfoOpen(!isAircraftInfoOpen);
        break;
      case "flight":
        setFlightInfoOpen(!isFlightInfoOpen);
        break;
      default:
        break;
    }
  };


  // Calculate the filled percentage of the distance
  const filledPercentage = (coveredDistance / totalDistance) * 80;

  return (

    <div className='Info-main-card'>
      <div className="flight-info-card">
        <div className='bg-linear'>
          <div className="header-card">
            <span className='close-btn-card'>
              <button className="close-button" onClick={onClose}>
                <img src="/assets/cardclose.png" />
              </button>
            </span>
            <div className="backgroud ">
              <span className='dep-arri'>
                <span className='dp'>{depCountry}</span>
                <span className='ar'>{arrivalCountry}</span>
              </span>

              <div className='dep-arri-2'>
                <span className="dp-code">{departureIataCode}</span>
                <span className="line"><img className="line1" src="./assets/line.png" /></span>
                <span  className="dp-code">{arrivalIataCode}</span>
              </div>
            </div>
          </div>
          <div className="scrollable-section" >
          <div className="status-container">
            <div className="flight-status">
            
              <span className='info-flight'> {flightNumber}</span>
              <div className="airline-status">
              <span className='info-status'>Status:         </span>
                <span className="airline-status">{airlineStatus}</span>
                </div> 
       
            </div>
            <div className="distance-line-container">
  <div className="distance-line remaining"></div>
  <div className="distance-line filled" style={{ width: `${filledPercentage}%` }}>
    {/* Plane Icon */}
    <img 
      src="/assets/smallplane.png" 
      alt="Plane" 
      className="small-plane-icon" 
      style={{ left: `${filledPercentage}%` }} // Keep this inline for dynamic positioning
    />
  </div>
</div>

<div className="flight-progress">
  <span className='info-covered-kilo'>{coveredDistance} km</span>
  <span className='info-total-kilo'>{totalDistance} km</span>
</div>
</div>
          {/* Distance Line Representation */}
          
        
          <div className="section" onClick={() => handleToggle('details')}>
          <h4>Status Information</h4>
          <span>{isDetailsOpen ? <img src="/assets/uplist.png"/> :  <img  src="/assets/downlist.png"/>  }
          </span>
        </div>
        <div className="status-info">
          <div className='abc'>
            <div className='png-actual'>
              <span className='actual'><img src="/assets/departed.png"/>Actual</span>
              <div className="bold1">
              <span  className="bold" >{actualArrival} </span>
              <span className="bold-para">UTC+5.5</span>
             
              </div>
            </div>

            <div className='ver-divider '></div>
            <div className='png-actual'>
              <span className='actual'><img src="/assets/arrival.png"/>Estimated</span>
              <div className="bold1">
              <span className="bold" >{estimatedArrival}
              <span className="bold-para">UTC+5.5</span>
           
              </span>
         
              </div>
            </div>
          </div>
          </div>
          <div className="status-info">
          <div className='divider'></div>
        <div className='abc'>
      <div className='png-actual'>
        <span className='longitude'>Scheduled</span>
        <div className="bold1">
          <span className="bold">{actualScheduled}</span>
          <span className="bold-para">UTC+5.5</span>
        </div>
      </div>

      <div className='ver-divider '></div>
      <div className='png-actual'>
        <span className='longitude'>Scheduled</span>
        <div className="bold1">
          <span className="bold">{estimatedScheduled}</span>
          <span className="bold-para">UTC+5.5</span>
        </div>
      </div>
    </div>
    </div>
   
        {isDetailsOpen && (
          
  <div className="status-info">

    {/* Scheduled Section */}
  
    {/* Scrollable Section */}
   
      {/* Terminal Info */}
      <div className="status-info">
        <div className='divider'></div>
        <div className='abc'>
          <div className='png-actual'>
            <span className='longitude'>Terminal</span>
            <div className="bold">
              <span>{depTerminal}</span>
            </div>
          </div>

          <div className='ver-divider '></div>
          <div className='png-actual'>
            <span className='longitude'>Terminal</span>
            <div className="bold">
              <span>{arrivalTerminal}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Gate Info */}
      <div className="status-info">
        <div className='divider'></div>
        <div className='abc'>
          <div className='png-actual'>
            <span className='longitude'>Gate</span>
            <div className="bold">
              <span>{depGate}</span>
            </div>
          </div>

          <div className='ver-divider '></div>
          <div className='png-actual'>
            <span className='longitude'>Gate</span>
            <div className="bold">
              <span>{arrivalGate}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Baggage Info */}
      <div className="status-info">
        <div className='divider'></div>
        <div className='baggage'>
          <span className='longitudes'>Baggage</span>
          <div className="bold">
            <span>{baggageInfo}</span>
          </div>
        </div>
      </div>
    </div>

)}


        <div className="section" onClick={() => handleToggle('live')}>
          <h4>Live Information</h4>
          <span>{isLiveInfoOpen ?   <img src="/assets/uplist.png"/> :  <img  src="/assets/downlist.png"/> }
          </span>
        </div>
        <div className="status-info">
          <div className='abc'>
            <div className='png-actual'>
              <span className='longitude'>Altitude</span>
              <div className="bold1">
              <span className="bold">{liveAltitude}</span>
              <span className="bold-para">ft</span>
              </div>
            </div>

            <div className='ver-divider '></div>
            <div className='png-actual'>
              <span className='longitude'>Speed</span>
              <div className="bold1">
              <span className="bold">{liveSpeed}</span>
              <span className="bold-para">km/h</span>
              </div>
            </div>
          </div>
        </div>
        {isLiveInfoOpen && (
          <div className="status-info">
            <div className="status-info">
              <div className='divider'></div>
              <div className='abc'>
                <div className='png-actual'>
                  <span className='longitude'>Latitude</span>
                  <div className="bold">
                  <span>{liveLatitude}</span>
                  </div>
                </div>

                <div className='ver-divider '></div>
                <div className='png-actual'>
                  <span className='longitude'>Longitude</span>
                  <div className="bold">
                  <span>{liveLongitude}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="status-info">
              <div className='divider'></div>
              <div className='abc'>
                <div className='png-actual'>
                  <span className='longitude'>Heading</span>
                  <div className="bold">
                  <span>{liveHeading}</span>
                  </div>
                </div>

                <div className='ver-divider '></div>
                <div className='png-actual'>
                  <span className='longitude'>Status</span>
                  <div className="bold">
                  <span>{airlineStatus}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='section-1'>
          <h4>Aircraft Information</h4>
        </div>
        <div className="aircraft-info">
          <div className='Aircaft-values'>
            <div className='a1'>
              <span className='longitude'>Airline</span>
              <div className="bold">
              <span>{airlineName}</span>
              </div>
            </div>

            <div className='a2'>
              <span className='longitude'>Aircraft Type</span>
              <div className="bold">
              <span>{airlineReg}</span>
              </div>
            </div>
          </div>
          <div className='airplaneImg'>
            <img className="airplan-img" src='/assets/airplaneImg.png' />
          </div>
        </div>

        <div className="section" onClick={() => handleToggle('flight')}>
          <h4>Flight Information</h4>
          <span>{isFlightInfoOpen ?
 
           <img src="/assets/uplist.png"/> :  <img  src="/assets/downlist.png"/> }
           
           </span>
        </div>
        <div className='departure-headingg'>
          <div className='a1'>
            <span className="small-heading">Departure</span>
          </div>
        </div>

        {isFlightInfoOpen && (
          <div className="flight-info-details1">
            <div className='a1'>
              <span className='city-longitude'>City</span>
              <div className="bold">
              <span>{depCity}</span>
              </div>
            </div>
            <div className='divider'></div>

            <div className='a1'>
              <span className='longitude' >Country</span>
              <div className="bold">
              <span>{depCountry}</span>
              </div>
            </div>
            <div className='divider'></div>
            <div className='a1'>
              <span className='longitude'>Airport</span>
              <div className="bold">
              <span>{depAirport}</span>
              </div>
            </div>
          </div>
        )}

        <div className='departure-heading'>
          <div className='a1'>
            <span className="small-heading">Arrival</span>
          </div>
        </div>

        {isFlightInfoOpen && (
          <div className="flight-info-details2">
            <div className='a1'>
              <span className='city-longitude'>City</span>
              <div className="bold">
              <span>{arrivalCity}</span>
              </div>
            </div>
            <div className='divider'></div>

            <div className='a1'>
              <span className='longitude'>Country</span>
              <div className="bold">
              <span>{arrivalCountry}</span>
              </div>
            </div>
            <div className='divider'></div>
            <div className='a1'>
              <span className='longitude'>Airport</span>
              <div className="bold">
              <span className="bottom">{arrivalAirport}</span>
              </div>
            </div>
       
   </div>
        )}
          
            <div className="last-card"></div>
            </div>
      </div>
   
      {/* {showAppCard && <AppCard />} */}
      <div className="flight-App-card">
      <AppCard />
      </div>
    </div>
    </div>

  );


}
)


  

      
  

export default FlightCard;